$primary-color: #1A1A1A;
$color-blue: #4e67ff;
$color-black: #000000;
$light-gray: #F6F6F8;
$color-white: #ffffff;
$color-gray: #2A2A2A;
$ternary-color: #555555;
$color-dark-gray: #444444;
$color-light-gray: #CCCCCC;
$border-color: #A9A9A9;
$contact-icon-color: #888888;
$text-color: #AAAAAA;
$green-color: #4CA655;