@import "../../styles/settings/_settings";

.about_sec_main {
    .about_sec_main_left {
        h1 {
            font-size: 30px;
            font-family: 'Poppins', sans-serif;
            text-transform: uppercase;
            font-weight: 600;
            padding-top: 10px;
            padding-bottom: 16px;

            @media screen and (max-width: 767px) {
                font-size: 20px;
            }
        }

        @media screen and (max-width: 767px) {
            text-align: center;
        }

        @media (min-width: 410px) and (max-width: 768px) {
            margin-bottom: 20px;
        }
    }

    /* Reusable.module.scss */
    .about_sec_main_right {
        padding: 0;
        position: relative;
        display: flex;
        overflow: hidden;

        img {
            object-fit: cover;
        }

        // .img_container {
        //     position: relative;
        //     width: 280px;
        //     height: 328px;
        //     img {
        //         width: 100%;
        //         height: 100%;
        //     }

        //     @media (min-width: 320px) and (max-width: 410px) {
        //         width: 200px;
        //         height: 234px;
        //     }

        //     @media (min-width: 410px) and (max-width: 510px) {
        //         width: 240px;
        //         height: 234px;
        //     }
        // }

        // .first_img {
        //     z-index: 1;

        //     @media (min-width: 768px) and (max-width: 1024px) {
        //         z-index: 2;
        //     }
        // }

        // .centered_img {
        //     position: absolute;
        //     top: 0;
        //     left: 240px;
        //     z-index: 1;

        //     @media (min-width: 320px) and (max-width: 767px) {
        //         display: none;
        //     }

        //     @media (min-width: 768px) and (max-width: 1024px) {
        //         position: absolute;
        //         bottom: 0;
        //         top: auto;
        //         left: 20px;
        //         z-index: 1;
        //     }
        // }

        // .last_img {
        //     position: absolute;
        //     bottom: 0;
        //     right: 0;
        //     z-index: 2;

        //     @media screen and (max-width: 767px) {
        //         right: 12px;
        //     }

        //     @media (min-width: 768px) and (max-width: 1024px) {
        //         position: absolute;
        //         bottom: -40px;
        //         right: 20px;
        //         z-index: 2;
        //     }
        // }

        @media screen and (max-width: 767px) {
            justify-content: flex-start;

            padding: 20px 12px 0 12px;

            img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .about_contact_no {
        display: none;

        @media screen and (max-width: 767px) {
            display: block;
            margin-top: 20px;

            a {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 5px;

                span {
                    width: 45px;
                    height: 45px;
                    border-radius: 50%;
                    border: 1px solid #a9a9a9;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                p {
                    margin: 0;
                }
            }
        }
    }

    @media (min-width:768px) and (max-width:1024px) {
        flex-direction: column;
        text-align: center;
    }

    @media screen and (max-width: 767px) {
        margin-top: 165px;
    }
}

.room_contact_no {
    display: flex;
    align-items: center;
    gap: 5px;

    @media screen and (max-width: 767px) {
        display: none;
    }
}