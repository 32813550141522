@import "../../styles/settings/_settings";

.home_banner {
    height: 650px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-position: 50%;
    position: relative !important;

    img {
        z-index: -1;
    }

    .banner_text {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height: 100%;
        // bottom: 150px;
        bottom: 50px;

        .banner_text_con {
            display: flex;
            justify-content: center;
            // align-items: center;
            flex-direction: column;

            h2 {
                color: $color-white;
                width: 60%;
                // text-align: center;
                font-size: 40px;
                line-height: 1.3;

                @media screen and (max-width: 767px) {
                    width: 100%;
                    padding-top: 10px;
                    font-size: 18px !important;
                    text-align: left;
                }
            }
        }

        .videobanner {
            object-fit: cover;
            width: 100%;
            height: 100%;
            // position: fixed;
            top: 0;
            left: 0;
            z-index: 10;
            background: #000;
            transition: 1s ease-in-out .5s;
            position: relative;
            padding-top: 56.25%;

            .video_bg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 0;

                @media screen and (max-width: 767px) {
                    // height: 70vh;
                }
            }

            @media screen and (max-width: 767px) {
                // height: 70vh;
            }
        }

        @media screen and (max-width: 767px) {
            bottom: 40px;
        }
    }

    .hyperlink_button {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
    }

    &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgb(0 0 0 / 34%);
    }

    .single_hotel_search_form_container {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 25%;
        bottom: 50%;
    }

    .home_banner_image {
        display: block;
        z-index: -1;
    }

    .mobiel_home_banner_image {
        display: none;
    }

    @media screen and (max-width: 767px) {
        height: 400px;

        .home_banner_image {
            display: none;
        }

        .mobiel_home_banner_image {
            display: block;
        }
    }
}

.banner_video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    // position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    background: #000;
    transition: 1s ease-in-out .5s;
    position: relative;
    padding-top: 56.25%;

    .video_bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;

        @media screen and (max-width: 767px) {}
    }

    @media screen and (max-width: 767px) {}
}

// .banner_video {
//     object-fit: cover;
//     width: 100%;
//     height: 100vh;
//     // position: fixed;
//     top: 0;
//     left: 0;
//     z-index: 10;
//     background: #000;
//     transition: 1s ease-in-out .5s;
//     position: relative;

//     .video_bg {
//         position: absolute;
//         top: 0;
//         left: 0;
//         width: 100%;
//         height: 100%;
//         border: 0;

//         @media screen and (max-width: 767px) {
//         }
//     }

//     @media screen and (max-width: 767px) {
//     }
// }


.banner_slider_wrapper {
    .slick_slide {
        text-align: center;
        position: relative;


        &:focus {
            outline: none;
        }

        .slick_slide_image {
            width: 100%;
            height: 100vh;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;
            // border-radius: 8px;
            box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
                0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
        }

        .slick_slide_text {
            color: #fff;
            padding: 10px;
            position: absolute;
            left: 50%;
            font-size: 1.5em;
            top: 50%;
            // width: 100%;
        }
    }

    .slick-prev:before,
    .slick-next:before {
        color: #777777;
    }
}



.booking_sec {
    background: $light-gray;
    overflow: hidden;

    .booking_cont {
        // background: $color-white;
        margin-top: 20px;
        padding: 0 12px;

        p {
            margin-bottom: 4px;
        }

        .booking_col {
            background: $color-white;
            // padding: 30px 10px;
            height: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 1px solid #D9D9D9;
            text-align: center;

            h4 {
                // color: $contact-icon-color;
                font-size: 35px;

                @media screen and (max-width: 767px) {
                    font-size: 20px;
                }

                @media screen and (min-width: 767px) and (max-width: 1240px) {
                    font-size: 30px;
                }
            }

            @media screen and (max-width: 767px) {
                height: 60px;
            }
        }

        .button_col {
            padding: 0;

            .check_now_btn {
                font-family: "Poppins", sans-serif;
                color: $color-white ;
                background: $primary-color;
                text-transform: uppercase;
                transition: 0.5s;
                text-align: center;
                display: flex;
                justify-content: center;
                font-size: 14px;
                align-items: center;
                width: 100%;
                height: 100%;
                border: 1px solid $primary-color;

                &:hover {
                    background: transparent;
                    color: $primary-color;
                }

                @media screen and (max-width: 767px) {
                    height: 60px;
                    width: 100%;
                }
            }
        }
    }

    .room_con {
        .room_left {
            h3 {
                padding-bottom: 20px;

                @media screen and (max-width: 767px) {
                    padding-top: 20px;
                    padding-bottom: 10px;
                }
            }


        }

        .room_center {
            position: relative;

            img {
                height: auto;
                /* Change height to auto */
                width: 100%;
                object-fit: cover;
            }

            @media (min-width:768px) and (max-width:1024px) {
                height: 300px;
                width: auto;
                /* Change width to auto */
                margin: 0 auto;
            }
        }

        .room_right {
            .room_right_bottom {
                display: flex;
                align-items: center;
                padding-bottom: 20px;

                .right_bottom_text {
                    width: 80%;
                    padding-left: 20px;

                    p {
                        margin: 0;
                        color: $primary-color;
                        font-size: 16px;


                    }

                    span {
                        color: $ternary-color;
                        font-size: 15px;
                    }

                    @media screen and (max-width: 767px) {
                        padding-left: 0;

                    }
                }

                @media screen and (max-width: 767px) {
                    flex-direction: column;
                    text-align: center;
                }

                @media (min-width:768px) and (max-width:1024px) {
                    justify-content: center;
                    padding-top: 20px;
                }
            }


        }



        @media (min-width:768px) and (max-width:1024px) {
            text-align: center;
        }
    }

    .button_sec {
        a {
            margin: 20px 0;

            @media screen and (max-width: 767px) {
                margin-top: 10px;
            }
        }
    }
}

.hall_sec {
    .image_sec {
        padding-top: 30px;

        .image_sec_left {
            margin: 0 auto;
            position: relative;
            outline: none;
            height: 450px;
            background-size: cover;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;
            position: relative;

            .conference_btn {
                position: absolute;
                bottom: 0;
                left: 0;
            }

            &:hover {
                transition: 0.5s;

                .image_overlay {
                    opacity: 1;

                }

                .conference_btn {
                    opacity: 0;
                }
            }

            @media screen and (max-width: 767px) {
                margin-bottom: 20px;
                height: 350px;
            }

        }

        .image_overlay {
            position: absolute;
            background-color: #00000075;
            height: 100%;
            width: 100%;
            transition: 0.5s ease;
            opacity: 0;
            padding: 20px;
            text-align: center;
            color: $color-white;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h3 {
                padding-bottom: 10px;
            }

            p {
                color: $color-white;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 1.5;
                text-align: center;
                letter-spacing: 0.03em;
            }

            a {
                background: $color-white;
                color: $primary-color;
                border: 1px solid $color-white;
                font-weight: 700;

                &:hover {
                    background: transparent;
                    border: 1px solid $color-white;
                    color: $color-white;
                }
            }
        }
    }
}

.activities_sec {
    overflow: hidden;

    .activities_slider {

        .activity_child {
            padding: 40px 0;

            .activity_bg_image {
                margin: 0 auto;
                position: relative;
                outline: none;
                height: 350px;
                width: 350px;
                background-size: cover;
                background-repeat: no-repeat;
                display: flex;
                justify-content: center;

                span {
                    position: absolute;
                    background: $primary-color;
                    left: 0;
                    padding: 5px 10px;
                    color: $color-white;
                    border-radius: 3px;
                }

                .activity_overlay {
                    position: absolute;
                    background-color: $color-white;
                    bottom: 10px;
                    width: 80%;
                    transition: 0.5s ease;
                    opacity: 0;
                    padding: 20px;
                    text-align: center;

                    p {
                        font-size: 14px;
                        // margin: 0;
                        line-height: 1.4;

                        a {
                            color: $green-color;
                        }
                    }
                }

                @media screen and (max-width: 767px) {
                    width: 100%;
                }
            }

            &:hover {
                transform: rotate(-5deg);
                transition: 0.5s;

                .activity_overlay {
                    opacity: 1;
                }
            }

            @media screen and (max-width: 767px) {
                padding: 40px 10px;

                &:hover {
                    transform: rotate(-3deg);
                }
            }
        }

        .explore_btn {
            text-align: center;
            margin: 40px 0;

            a {
                color: $primary-color
            }
        }
    }
}

.help_sec {
    height: 143px;
    position: relative;
    display: flex;
    align-items: center;
    background-color: #D1B7A8;

    .help_sec_child {
        position: relative;
    }

    h2,
    p {
        color: $primary-color;
    }

    @media screen and (max-width: 767px) {
        height: 100%;
    }
}

.testimonial_sec {
    // background-image: url('/images/testimonial-bg.jpg');
    // background-size: cover;
    overflow: hidden;

    h2 {
        color: $color-white;
    }

    .background_image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }


    .testimonial {
        padding: 20px 0;

        .testi_left {
            position: relative;

            @media screen and (max-width: 767px) {
                height: 200px;
                margin-bottom: 20px;
            }
        }

        .testi_right {
            text-align: center;

            h3 {
                color: $color-white;
                padding-bottom: 10px;
            }

            h4 {
                color: $color-white;
                padding-bottom: 7px;

                @media screen and (max-width: 767px) {
                    text-align: center;
                }
            }

            p {
                color: $color-light-gray;
                padding: 20px 0;
                font-size: 14px;
                margin: 0;
            }
        }

        @media screen and (max-width: 767px) {
            padding: 0;
        }
    }

}

.blogs_sec {
    .blogs {
        padding-top: 20px;
        display: flex;
        gap: 20px;

        .single_blog_parent {
            width: 33%;
            box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            transition: .5s;

            .all_blog_image {
                position: relative;
                height: 250px;
                width: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .all_blog_content {
                padding: 20px;
                // height: 250px;

                p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 4;
                    margin-bottom: 10px;
                }

                h4 {
                    color: $primary-color;
                    margin-bottom: 10px !important;
                }
            }

            &:hover {
                background-color: #F5F5F5;
            }
        }

        @media screen and (max-width: 767px) {
            flex-wrap: wrap;

            .single_blog_parent {
                width: 100%;
                text-align: left;

                .all_blog_image {

                    img {
                        width: 100%;
                    }
                }

                .all_blog_content {
                    padding: 10px;

                    p {
                        text-align: left;
                    }
                }
            }
        }
    }

    .button_sec {
        margin-top: 40px;
    }
}

.privacy_policy {
    h3 {
        padding-top: 10px;
    }

    ul {
        padding: 0;

        li {
            list-style-type: none;
            position: relative;
            margin-top: 2px;
            padding-left: 18px;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                width: 8px;
                height: 8px;
                background-color: $border-color;
                // border-radius: 50%;
                top: 10px;
            }
        }
    }

    table {
        border-collapse: collapse;
        margin: 20px 0;
    }

    th,
    td {
        border: 1px solid #ccc;
        padding: 8px;
    }
}