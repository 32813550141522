// .booking_form {
//     width: 100%;
//     height: 100%;
//     background: rgba(200, 200, 200);
//     position: fixed;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     z-index: 99;

//     .booking_form_wrapper {
//         width: 80vw;
//         height: 80vh;
//         box-shadow: 0 5px 16px gray;
//         background: #fff;

//         .booking_form_main {
//             .form_group {
//                 margin-bottom: 20px;

//                 label {
//                     margin-bottom: 5px;
//                 }

//                 input {
//                     height: 50px;
//                     border: 1px solid #303715;
//                     border-radius: 5px;
//                     // box-shadow: 0 2px 13px #ccc3;

//                     &:focus {
//                         outline: none !important;
//                     }

//                     &:read-only {
//                         background-color: #8A9656;
//                         color: white;
//                         border: none;
//                     }

//                 }

//             }

//             .button_group {
//                 display: flex;
//                 justify-content: center;

//                 button {
//                     background-color: #303715;
//                     width: auto;
//                     padding: 14px 40px;
//                     color: white;
//                     border: none;
//                     border-radius: 5px;
//                     align-items: center;

//                     &:hover {
//                         background-color: #8A9656;
//                         transition: all 0.5s;
//                     }
//                 }
//             }

//         }
//     }

// }
.search_form {
  position: absolute;
  bottom: 20px;
}

.homepage_search_box {
  // border-radius: 50px;
  text-align: center;
  display: flex;
  // position: sticky;
  justify-content: space-between;
  // padding: 15px 0;
  align-items: center;
  background: white;
  width: 720px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;

  .searchbox_child,
  .singlehotel_searchbox_child {
    width: 45%;
    text-align: start;
    border-right: 1px solid #ddd;
    padding: 14px 20px;
    // width: 28%;
    // display: flex;
    // align-items: center;

    p {
      margin: 0;
      font-weight: 400;
      // font-size: 14px;
      // color: black;

      margin-bottom: 2px;
      color: #454444;
      font-size: 13px;


      @media screen and (max-width: 767px) {
        // margin-bottom: 5px;
        font-size: 12px !important;
      }
    }

    .button_con,
    .search_item {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;

      p {
        font-size: 45px;
        color: #303715;
        margin: 0;
        font-family: "font-family-heading";
      }

      .inc_dec_btn {
        div {
          button {
            border: none;
            background: transparent;
          }
        }
      }
    }

    .select_con {
      select {
        border: none;
        border-radius: 5px;
        background: white;
        width: 100%;
        color: #222222;
        font-family: "Poppins", sans-serif;
        // border-radius: 50px;

        &:focus {
          outline: none;
        }

        @media screen and (max-width: 767px) {
          // text-align: center;
        }
      }
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      padding: 0;
      margin: 0;
      border: none;
      background: white;
      border-radius: 10px;
      padding: 0px 5px;
    }
  }

  .singlehotel_searchbox_child {

    // width: 40%;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 5px;
    }
  }

  .searchbox_btn_child {
    .submit_btn {
      background-color: #222222;
      border: none;
      // border-radius: 50px;
      color: white;
      width: 78px;
      height: 85px;
      transition: 0.5s;

      &:hover {
        background-color: #333;
      }
    }

    .submit_btn_mobile {
      display: none;
    }

    @media screen and (max-width: 767px) {
      width: 100%;

      .submit_btn {
        display: none;
      }

      .submit_btn_mobile {
        display: block;
        font-size: 12px;
        display: block;
        border: 1px solid #333;
        background: #fff;
        padding: 10px 20px;
        border-radius: 25px;
        margin: 0 auto;
        min-width: 100%;
        color: #333;
        // height: 50px;
        text-transform: uppercase;
      }
    }
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    padding: 10px;
    width: 100%;
    border-radius: 15px;
    left: 0;
    position: unset;
    top: 0;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  }
}

.test_search_box {
  // border-radius: 50px;
  text-align: center;
  display: flex;
  z-index: 99;
  position: sticky;
  justify-content: space-between;
  align-items: center;
  background: white;
  // border: 2px solid #333;
  top: 0;
  width: 900px;
  margin: 0 auto;

  .searchbox_child,
  .singlehotel_searchbox_child {
    width: 30%;
    text-align: start;
    border-right: 1px solid #ddd;
    padding: 14px 20px;

    p {
      margin: 0;
      font-weight: 400;
      font-size: 14px;
      text-align: left;

      @media screen and (max-width: 767px) {
        margin-bottom: 2px;
        font-size: 12px;
        text-align: left;
      }
    }

    .button_con,
    .search_item {
      //   display: flex;
      align-items: center;
      width: 100%;

      p {
        font-size: 45px;
        color: #303715;
        margin: 0;
        font-family: "font-family-heading";
      }

      .inc_dec_btn {
        div {
          button {
            border: none;
            background: transparent;
          }
        }
      }
    }

    .select_con {
      select {
        border: none;
        border-radius: 5px;
        width: 100%;
        color: #222222;
        font-family: "Poppins", sans-serif;
        background: white;

        &:focus {
          outline: none;
        }

        option {
          &:hover {
            background: red;
          }
        }

        @media screen and (max-width: 767px) {
          font-size: 14px;
        }
      }

      select option:hover {
        color: white !important;
        background-color: blue !important;
      }
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 10px;
      padding: 0;
      border: none;
      background: white;
      border-radius: 10px;
      padding: 5px 10px;
    }
  }

  .singlehotel_searchbox_child {
    width: 40%;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  .searchbox_btn_child {
    .submit_btn {
      // background-color: #8B9556;
      // background-color: #222222;
      border: none;
      background-color: #222222;
      // border-radius: 50px;
      color: white;
      // width: 77px;
      height: 83px;
      width: 80px;
      transition: 0.5s;

      &:hover {
        background-color: #333;
        color: black;
      }
    }

    .submit_btn_mobile {
      display: none;
    }

    @media screen and (max-width: 767px) {
      width: 100%;

      .submit_btn {
        display: none;
      }

      .submit_btn_mobile {
        display: block;
        font-size: 14px;
        display: block;
        border: 1px solid white;
        background: transparent;
        padding: 10px 20px;
        border-radius: 25px;
        margin: 0 auto;
        min-width: 100%;
        color: white;
        height: 50px;
        text-transform: uppercase;
      }
    }
  }

  @media screen and (max-width: 767px) {
    flex-wrap: wrap;
    width: 100%;
    border-radius: 15px;
    padding: 10px;
    background: black;
    border: none;
  }
}

.select_label {

  font-weight: 400;
  font-size: 14px;
  color: #555;
}

.default_text {
  color: #222222 !important;
}

// .optionItem,.optionCounter{
//     display: flex;
// }
.options {

  .optionItem,
  .optionCounter {
    display: flex;
  }
}