@font-face {
    font-family: 'Cavier-Bold';
    src: url('../fonts/Caviar/CaviarDreams-Bold.woff2') format('woff2'),
        url('../fonts/Caviar/CaviarDreams-Bold.woff') format('woff'),
        url('../fonts/Caviar/CaviarDreams_Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

// @font-face {
//     font-family: 'HelveticaNowText-Bold';
//     src: url('../fonts/Helvatiaca/HelveticaNowText-Bold.woff2') format('woff2'),
//         url('../fonts/Helvatiaca/HelveticaNowText-Bold.woff') format('woff'),
//         url('../fonts/Helvatiaca/HelveticaNowText-Bold.ttf') format('truetype');
//     font-weight: bold;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'HelveticaNowText-Medium';
//     src: url('../fonts/Helvatiaca/HelveticaNowText-Medium.woff2') format('woff2'),
//         url('../fonts/Helvatiaca/HelveticaNowText-Medium.woff') format('woff'),
//         url('../fonts/Helvatiaca/HelveticaNowText-Medium.ttf') format('truetype');
//     font-weight: 500;
//     font-style: normal;
//     font-display: swap;
// }